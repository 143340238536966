import Filter from 'bad-words';
import swal from 'sweetalert2';

const generateProfanityListAsHtml = (fieldName, profanityList) => {
  let profanityListAsHtml = '';
  profanityList.map(baseBadWord =>
    profanityListAsHtml += `<span class="badge badge-danger badge-pill" style="text-transform:none">${baseBadWord}</span>`
  );
  return (
    `<p class="lead">Are you sure you want to use the following unacceptable word(s) in the <b>'${fieldName}'</b> field?</p>
    <p class="lead">${profanityListAsHtml}</p>`
  );
};

class TBIBadWordsFilter {
  constructor(props) {
    const filter = new Filter();
    Object.assign(this, {
      props: props,
      filter: filter,
      getProfanityList: this._getProfanityList.bind(this),
      confirmProfanityUsages: this._confirmProfanityUsages.bind(this),
      confirmProfanityUsage: this._confirmProfanityUsage.bind(this),
      clean: this._clean.bind(this)
    });
    this.loadBadWordsFromDb();
    return this;
  }
  loadBadWordsFromDb = async () => {
    const existingDbSettings = await this.props.firebase.getDbSettings(),
      dbSettingsRef = await existingDbSettings.once('value'),
      dbSettings = await dbSettingsRef.val();
    if (dbSettings) {
      Object.keys(dbSettings).map(key => {
        this.filter.addWords(...dbSettings[key].badWordsList);
        this.filter.removeWords(...dbSettings[key].badWordsExcludedList);
        return void (0);
      });
    }
    return this;
  }
  _getProfanityList = async text => {
    const profanityList = [],
      textAsWordArray = (text || '').split(/\W+/);
    await textAsWordArray.map(word => {
      if (this.filter.isProfane(word)) {
        profanityList.push(word);
      }
      return void (0);
    });
    // await this.filter.list.map(badWord => {
    //   if (!this.filter.exclude.includes(badWord.toLowerCase()) && text.includes(badWord)) {
    //     profanityList.push(badWord);
    //   }
    //   return void (0);
    // });
    return [...new Set(profanityList)];
  }
  _confirmProfanityUsages = async (fieldName, fieldValues) => {
    let results = true,
      fieldValue = null;
    for (let i = 0; i < fieldValues.length; i++) {
      fieldValue = fieldValues[i];
      if (!(await this.confirmProfanityUsage(fieldName, fieldValue))) {
        results = false;
        return;
      }
    }
    return results;
  }
  _confirmProfanityUsage = async (fieldName, fieldValue) => {
    const profanityList = await this.getProfanityList(fieldValue);
    if (profanityList.length === 0) {
      return true;
    }
    const html = generateProfanityListAsHtml(fieldName, profanityList),
      result = await swal.fire({
        type: 'warning',
        title: 'Bad Words Detected?!',
        html: html,
        footer: '<i>Note: Unacceptable words are replaced with * when displayed.</i>',
        showConfirmButton: true,
        confirmButtonText: 'Yes',
        showCancelButton: true,
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-lg btn-success mx-2',
          cancelButton: 'btn btn-lg btn-link mx-2'
        },
        buttonsStyling: false,
        width: '38em'
      });
    if (result) {
      return result.value;
    }
  }
  _clean = string => {
    return this.filter.clean(string || '');
  }
}

export default TBIBadWordsFilter;