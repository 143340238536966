import TBIBadWordsFilterContext, {
  withTBIBadWordsFilter
} from './TBIBadWordsFilterContext';
import TBIBadWordsFilter from './TBIBadWordsFilter';

export default TBIBadWordsFilter;
export {
  TBIBadWordsFilterContext,
  withTBIBadWordsFilter
};
