import React from 'react';
import {
  facebookSocialMediaLink,
  linkedinSocialMediaLink,
  twitterSocialMediaLink,
  websiteSocialMediaLink,
  youtubeSocialMediaLink
} from '../../socialMediaLinks';
import axios from 'axios';

const shallowCompare = (instance, nextProps, nextState, consoleLogResults) => {
  return (
    !shallowEqual(instance.props, nextProps, consoleLogResults, true) ||
    !shallowEqual(instance.state, nextState, consoleLogResults)
  );
};
const shallowEqual = (objA, objB, consoleLogResults, isProps) => {
  const isPropsText = isProps ? 'props' : 'state';
  if (objA === objB) {
    if (consoleLogResults) console.log(`${isPropsText} :: objA === objB: true`);
    return true;
  }
  if (typeof objA !== 'object' || objA === null ||
    typeof objB !== 'object' || objB === null) {
    if (consoleLogResults) console.log(`${isPropsText} :: typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null: ${typeof objA !== 'object'} || ${objA === null} || ${typeof objB !== 'object'} || ${objB === null}`);
    return false;
  }
  var keysA = Object.keys(objA);
  var keysB = Object.keys(objB);
  if (keysA.length !== keysB.length) {
    if (consoleLogResults) console.log(`${isPropsText} :: keysA.length !== keysB.length: ${keysA.length} !== ${keysB.length}`);
    return false;
  }
  var bHasOwnProperty = hasOwnProperty.bind(objB);
  for (var i = 0; i < keysA.length; i++) {
    if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
      if (consoleLogResults) {
        console.log(`${isPropsText} :: !bHasOwnProperty('${keysA[i]}') || objA['${keysA[i]}'] !== objB['${keysA[i]}']: ${!bHasOwnProperty(keysA[i])} || ${objA[keysA[i]] !== objB[keysA[i]]}`);
      }
      return false;
    }
  }
  if (consoleLogResults) console.log(`${isPropsText} :: shallowEqual: true`);
  return true;
};
const sleep = (ms) => {
  return new Promise(resolve =>
    setTimeout(resolve, ms));
};
const isEmptyObject = (object) => {
  return !object || (Object.entries(object).length === 0 && object.constructor === Object);
}
const handleLoadBlob = (blob, handleLoadBlobComplete) => {
  const handleFileReaderLoadEnd = e => {
    e.preventDefault();
    handleLoadBlobComplete(e.target.result);
  };
  if (blob) {
    const fileReader = new FileReader();
    fileReader.onloadend = handleFileReaderLoadEnd;
    fileReader.readAsDataURL(blob);
  }
};
const filterDbBusinessProfiles = (dbBusinessProfiles, businessGroup) => {
  const filteredDbBusinessProfiles = {};
  Object.keys(dbBusinessProfiles || {}).map(key => {
    const dbBusinessProfile = dbBusinessProfiles[key];
    if (!!dbBusinessProfile.businessGroups[businessGroup]) {
      filteredDbBusinessProfiles[key] = dbBusinessProfile;
    }
    return void (0);
  });
  return filteredDbBusinessProfiles;
};
const getSocialMediaLinkDetails = type => {
  const socialMediaLinkDetails = {
    type: type,
    icon: null,
    name: null,
    title: null
  };
  switch (type) {
    case facebookSocialMediaLink:
      socialMediaLinkDetails.icon = 'fa-facebook-square';
      socialMediaLinkDetails.name = 'Facebook';
      socialMediaLinkDetails.title = 'Follow us on Facebook';
      break;
    case linkedinSocialMediaLink:
      socialMediaLinkDetails.icon = 'fa-linkedin';
      socialMediaLinkDetails.name = 'Linkedin';
      socialMediaLinkDetails.title = 'Connect with us on Linkedin';
      break;
    case twitterSocialMediaLink:
      socialMediaLinkDetails.icon = 'fa-twitter-square';
      socialMediaLinkDetails.name = 'Twitter';
      socialMediaLinkDetails.title = 'Follow us on Twitter';
      break;
    case websiteSocialMediaLink:
      socialMediaLinkDetails.icon = 'fa-globe';
      socialMediaLinkDetails.name = 'Website';
      socialMediaLinkDetails.title = 'Checkout our Website';
      break;
    case youtubeSocialMediaLink:
      socialMediaLinkDetails.icon = 'fa-youtube-square';
      socialMediaLinkDetails.name = 'Youtube';
      socialMediaLinkDetails.title = 'Subscribe to our Youtube Channel';
      break;
    default:
      throw new Error(`Social Media Link '${type}' not found.`);
  }
  return socialMediaLinkDetails;
};
const arrayUnique = array => {
  var a = array.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j])
        a.splice(j--, 1);
    }
  }
  return a;
};
const formatBytes = (bytes, dp = 2) => {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const oneKiloByte = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(oneKiloByte));
  const fractionDigits = dp < 0
    ? 0
    : dp;
  const refactoredBytes = parseFloat((bytes / Math.pow(oneKiloByte, i)).toFixed(fractionDigits));
  const formattedBytes = `${refactoredBytes} ${sizes[i]}`;
  return formattedBytes;
};
const formatInteger = (integer) => (
  integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
);
const keyCodes = {
  backspace: 8,
  tab: 9,
  enter: 13,
  space: 32,
  arrowLeft: 37,
  arrowRight: 39,
  colon: 59,
  divide: 111,
  semiColon: 186,
  forwardSlash: 191
};
const isShiftPlusTab = (shiftKey, keyCode) => {
  if (!shiftKey) {
    return false;
  }
  return (keyCode === keyCodes.tab);
};
const isShiftSemiColon = (shiftKey, keyCode) => {
  if (!shiftKey) {
    return false;
  }
  return (keyCode === keyCodes.semiColon || keyCode === keyCodes.colon);
};
const isNumeric = keyCode => {
  return (keyCode >= 48 && keyCode <= 57)
    || (keyCode >= 96 && keyCode <= 105);
};
const isAlpha = keyCode => {
  return (keyCode >= 65 && keyCode <= 90);
};
const handleKeyDown = (handleKeyDownOptions) => {
  const {
    e,
    allowOptions,
    validKeyCodes
  } = handleKeyDownOptions,
    {
      shiftKey,
      which: keyCode
    } = e,
    {
      shiftPlusTab,
      shiftSemiColon,
      numeric,
      alpha
    } = allowOptions;
  if ((shiftPlusTab && isShiftPlusTab(shiftKey, keyCode))
    || (shiftSemiColon && isShiftSemiColon(shiftKey, keyCode))
    || (numeric && isNumeric(keyCode))
    || (alpha && isAlpha(keyCode))
    || validKeyCodes.includes(keyCode)) {
    return true;
  }
  e.preventDefault();
  return false;
};
const handleKeyDownForDate = e => {
  return handleKeyDown({
    e,
    allowOptions: {
      shiftPlusTab: true,
      shiftSemiColon: true,
      numeric: true
    },
    validKeyCodes: [
      keyCodes.backspace,
      keyCodes.enter,
      keyCodes.tab,
      keyCodes.arrowLeft,
      keyCodes.arrowRight,
      keyCodes.divide,
      keyCodes.forwardSlash
    ]
  });
};
const handleKeyDownForTime = e => {
  return handleKeyDown({
    e,
    allowOptions: {
      shiftPlusTab: true,
      shiftSemiColon: true,
      numeric: true
    },
    validKeyCodes: [
      keyCodes.backspace,
      keyCodes.enter,
      keyCodes.tab,
      keyCodes.arrowLeft,
      keyCodes.arrowRight
    ]
  });
};
const getAppVersionText = () => {
  let appVersionText = `${process.env.REACT_APP_NAME} v${process.env.REACT_APP_BUILD_VERSION}`;
  return appVersionText;
};
const getBackgroundFullscreenImage = key => {
  let backgroundFullscreenImage = null;
  switch (key) {
    case 'rico':
      backgroundFullscreenImage = {
        key: 'rico',
        xs: require('assets/img/tolaga-bay-inn/background-images/full-screen/rico-xs.jpg'),
        sm: require('assets/img/tolaga-bay-inn/background-images/full-screen/rico-sm.jpg'),
        md: require('assets/img/tolaga-bay-inn/background-images/full-screen/rico-md.jpg'),
        lg: require('assets/img/tolaga-bay-inn/background-images/full-screen/rico-lg.jpg'),
        xl: require('assets/img/tolaga-bay-inn/background-images/full-screen/rico.jpg')
      };
      break;
    default:
      throw new Error(`${key} not found.`);
  }
  return backgroundFullscreenImage;
};
const DATE_MOMENT_FORMAT = 'DD/MM/YYYY';
const TIME_MOMENT_FORMAT = 'HH:mm:ss';
const DATE_TIME_MOMENT_FORMAT = `${DATE_MOMENT_FORMAT} ${TIME_MOMENT_FORMAT}`;
const ISO8601_DATE_FORMAT = 'YYYY-MM-DD';
const initialiseGoogleAnalyticsEmbedAPI = () => {
  const loadGA = () => {
    (function (w, d, s, g, js, fs) {
      g = w.gapi || (w.gapi = {}); g.analytics = { q: [], ready: function (f) { this.q.push(f); } };
      js = d.createElement(s); fs = d.getElementsByTagName(s)[0];
      js.src = 'https://apis.google.com/js/platform.js';
      fs.parentNode.insertBefore(js, fs); js.onload = function () { g.load('analytics'); };
    }(window, document, 'script'));
  };
  if (typeof window === 'undefined') {
    return false;
  }
  if (isEmptyObject(window.gapi)) {
    loadGA();
  }
  return window.gapi;
}
const getAccessTokenFromServiceAccount = async (baseCloudFunctionsURL, uid) => {
  const baseGetAccessTokenFromServiceAccountURL = `${baseCloudFunctionsURL}/getAccessTokenFromServiceAccount`;
  const uidParam = `uid=${uid}`;
  const getAccessTokenFromServiceAccountURL = `${baseGetAccessTokenFromServiceAccountURL}?${uidParam}`;
  let response = {
    data: {
      token: ''
    }
  };
  try {
    console.log(`getAccessTokenFromServiceAccountURL: ${getAccessTokenFromServiceAccountURL}`);
    response = await axios.get(getAccessTokenFromServiceAccountURL);
    // console.log(`Get Access Token From Service Account - response: ${JSON.stringify(response, null, 2)}`);
  } catch (error) {
    console.log(`Get Access Token From Service Account Error: ${error.message || error.response.data}`);
    response = error.response || response;
  } finally {
    return response;
  }
};
const SendUsAnEmail = (props) => {
  const defaultTitle = 'Click here to send us an email';
  const {
    subject,
    title,
    className
  } = props;
  return (
    <a href={`mailto:tolagabayinn@gmail.com?subject=${subject}`} title={`${title || defaultTitle}`} className={`${className || ''}`}>
      tolagabayinn&#64;gmail&#46;com
    </a>
  );
};
const specialCharsRegex = /[.*+?^${}()|[\]\\]/g; // https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_special_characters
const escapeRegexCharacters = value => {
  return value
    ? value.trim().replace(specialCharsRegex, '\\$&')
    : value;
};
const getDataFromFirebase = async (baseCloudFunctionsURL, uid, dbObject, dbField, dbValue) => {
  const baseGetAccessTokenFromServiceAccountURL = `${baseCloudFunctionsURL}/getDataFromFirebase`;
  const uidParam = `uid=${uid}`;
  const dbObjectParam = `dbObject=${dbObject}`;
  const dbFieldParam = `dbField=${dbField}`;
  const dbValueParam = `dbValue=${dbValue}`;
  const getDataFromFirebaseURL = `${baseGetAccessTokenFromServiceAccountURL}?${uidParam}&${dbObjectParam}&${dbFieldParam}&${dbValueParam}`;
  let response = {
    data: {
      results: {}
    }
  };
  try {
    console.log(`getDataFromFirebaseURL: ${getDataFromFirebaseURL}`);
    response = await axios.get(getDataFromFirebaseURL);
    // console.log(`Get Data From Firebase - response: ${JSON.stringify(response, null, 2)}`);
  } catch (error) {
    console.log(`Get Data From Firebase Error: ${error.message || error.response.data}`);
    response = error.response || response;
  } finally {
    return response;
  }
};
const wordCharacterRegex = /[a-z0-9_]/i; // http://www.ecma-international.org/ecma-262/5.1/#sec-15.10.2.6
const whitespacesRegex = /\s+/;
const AutosuggestHighlightMatch = (text, query) => {
  const handleFilter = word => (
    word.length > 0
  );
  const handleReduce = (result, word) => {
    const wordLength = word.length;
    const regex = new RegExp(escapeRegexCharacters(word), 'i');
    const index = text.search(regex);
    if (index > -1) {
      const indexPlusWordLength = index + wordLength;
      const beginning = text.slice(0, index);
      const middle = new Array(wordLength + 1).join(' ');
      const ending = text.slice(indexPlusWordLength);
      text = `${beginning}${middle}${ending}`;
      result.push([index, indexPlusWordLength]);
    }
    return result;
  };
  const handleSort = (match1, match2) => (
    match1[0] - match2[0]
  );
  return query
    .trim()
    .split(whitespacesRegex)
    .filter(handleFilter)
    .reduce(handleReduce, [])
    .sort(handleSort);
};
const AutosuggestHighlightParse = (text, matches) => {
  const getMatch = (value, start = 0, end = Infinity, highlight = false) => (
    {
      text: end === Infinity
        ? value
        : value.slice(start, end),
      highlight: highlight
    }
  );
  const handleMap = (match, i) => {
    const startIndex = match[0];
    const endIndex = match[1];
    const isLastMatch = i === matchesLength - 1
    const nextMatchStartIndex = !isLastMatch
      ? matches[i + 1][0]
      : Infinity;
    const textLength = text.length;
    const nextIndex = isLastMatch
      ? textLength
      : nextMatchStartIndex;
    result.push(getMatch(text, startIndex, endIndex, true));
    if (endIndex < nextIndex) {
      result.push(getMatch(text, endIndex, nextIndex));
    }
    return void (0);
  };
  const matchesLength = matches.length;
  const result = [];
  if (matchesLength === 0) {
    result.push(getMatch(text));
  } else {
    const firstMatchStartIndex = matches[0][0];
    if (firstMatchStartIndex > 0) {
      result.push(getMatch(text, 0, firstMatchStartIndex));
    }
  }
  matches.map(handleMap);
  return result;
};

export default shallowCompare;
export {
  shallowEqual,
  sleep,
  isEmptyObject,
  handleLoadBlob,
  filterDbBusinessProfiles,
  getSocialMediaLinkDetails,
  arrayUnique,
  formatBytes,
  formatInteger,
  keyCodes,
  isShiftPlusTab,
  isNumeric,
  isAlpha,
  handleKeyDown,
  handleKeyDownForDate,
  handleKeyDownForTime,
  getAppVersionText,
  getBackgroundFullscreenImage,
  DATE_MOMENT_FORMAT,
  TIME_MOMENT_FORMAT,
  DATE_TIME_MOMENT_FORMAT,
  ISO8601_DATE_FORMAT,
  initialiseGoogleAnalyticsEmbedAPI,
  getAccessTokenFromServiceAccount,
  SendUsAnEmail,
  specialCharsRegex,
  escapeRegexCharacters,
  getDataFromFirebase,
  wordCharacterRegex,
  whitespacesRegex,
  AutosuggestHighlightMatch,
  AutosuggestHighlightParse
};