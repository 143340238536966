import React from 'react';
import {
  withRouter
} from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody
} from 'reactstrap';
import {
  compose
} from 'recompose';
import AuthUserContext from './authUserContext';
import {
  withFirebase
} from './index';
import LoadingOverlayModal from '../App/LoadingOverlayModal';
import FullscreenBackgroundImage from '../App/FullscreenBackgroundImage';
import {
  SendUsAnEmail
} from '../App/Utilities';

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount = async () => {
      this.listener = await this.props.firebase.authUserListener(authUser => {
        // if (authUser && !condition(authUser)) {
        //   this.props.history.push('/WebApp/Login');
        // }
      }, () => {
        this.props.history.push('/WebApp/Login');
      });
    }
    componentWillUnmount = () => {
      this.listener();
    }
    render = () => {
      return (
        <AuthUserContext.Consumer>
          {
            authUser =>
              !authUser
                ? <LoadingOverlayModal />
                : condition(authUser)
                  ? <Component {...this.props} authUser={authUser} />
                  : <>
                    <FullscreenBackgroundImage />
                    <div
                      className="page-header"
                      style={{
                        backgroundColor: 'transparent',
                        marginTop: '0'
                      }}
                    >
                      <div className="filter" />
                      <Container>
                        <Row>
                          <Col className="mx-auto" md="8">
                            <Card className="no-transition">
                              <CardHeader>
                                <h1 className="text-danger">Web App Access Denied!</h1>
                              </CardHeader>
                              <CardBody>
                                <h3>
                                  Need to discuss your options? Send us an email so we know.{' '}
                                  <SendUsAnEmail
                                    subject="Web App Access Denied!"
                                    className="text-info"
                                  />
                                </h3>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </>
          }
        </AuthUserContext.Consumer>
      );
    }
  }
  return compose(
    withRouter,
    withFirebase,
  )(WithAuthorization);
};

export default withAuthorization;
