import React from 'react';
import AuthUserContext from './authUserContext';
import {
  withFirebase
} from './index';
import EmailVerificationView from './EmailVerificationView';

const needsEmailVerificationCondition = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider =>
        provider.providerId)
    .includes('password');
const withEmailVerification = Component => {
  class WithEmailVerification extends React.Component {
    render = () => {
      return (
        <AuthUserContext.Consumer>
          {
            authUser =>
              needsEmailVerificationCondition(authUser)
                ? <EmailVerificationView />
                : <Component {...this.props} />
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithEmailVerification);
};

export default withEmailVerification;
