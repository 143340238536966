import FirebaseContext, {
    withFirebase
}  from './firebaseContext';
import Firebase from './firebase';
import withAuthentication from './withAuthentication';
import withAuthorization from './withAuthorization';
import withEmailVerification from './withEmailVerification';

export default Firebase;
export {
    FirebaseContext,
    withFirebase,
    withAuthentication,
    withAuthorization,
    withEmailVerification
};