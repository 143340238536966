import React from 'react';

const TBIBadWordsFilterContext = React.createContext(null);

export const withTBIBadWordsFilter = Component => props => (
  <TBIBadWordsFilterContext.Consumer>
    {
      badWordsFilter =>
        <Component {...props} badWordsFilter={badWordsFilter} />
    }
  </TBIBadWordsFilterContext.Consumer>
);

export default TBIBadWordsFilterContext;