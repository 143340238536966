import React, {
  Component
} from 'react';
import PropTypes from 'prop-types';
import {
  getBackgroundFullscreenImage
} from '../App/Utilities';

const propTypes = {
  backgroundImage: PropTypes.shape({
    key: PropTypes.string,
    xs: PropTypes.string,
    sm: PropTypes.string,
    md: PropTypes.string,
    lg: PropTypes.string,
    xl: PropTypes.string
  }),
  backgroundImageName: PropTypes.string,
  hidden: PropTypes.bool,
  handleLoad: PropTypes.func,
  handleError: PropTypes.func
},
  defaultProps = {
    backgroundImageName: 'rico',
    hidden: false
  };

class FullscreenBackgroundImage extends Component {
  constructor(props) {
    super(props);
    const {
      backgroundImage,
      backgroundImageName
    } = this.props;
    this.state = {
      backgroundImage: backgroundImage || getBackgroundFullscreenImage(backgroundImageName)
    };
  }
  render = () => {
    const {
      hidden,
      handleLoad,
      handleError
    } = this.props;
    const {
      backgroundImage
    } = this.state;
    return (
      <img
        className={`background-image-full-screen${hidden
          ? ' d-none'
          : ''}`}
        alt={backgroundImage.key}
        srcSet={`
          ${backgroundImage.xs} 1w,
          ${backgroundImage.sm} 576w,
          ${backgroundImage.md} 768w,
          ${backgroundImage.lg} 992w,
          ${backgroundImage.xl} 1200w
        `}
        sizes="
          (max-width: 575.98px) 100vw,
          (max-width: 767.98px) 75vw,
          (max-width: 991.98px) 50vw,
          (max-width: 1199.98px) 33vw,
          (max-width: 2399,98px) 25vw
        "
        onLoad={handleLoad}
        onError={handleError}
      />
    );
  }
}

FullscreenBackgroundImage.propTypes = propTypes;
FullscreenBackgroundImage.defaultProps = defaultProps;

export default FullscreenBackgroundImage;