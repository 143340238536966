import {
  Component
} from 'react';
import {
  withRouter
} from 'react-router-dom';
import ReactGA from 'react-ga';

class GoogleAnalytics extends Component {
  constructor(props) {
    super(props);
    const {
      REACT_APP_GOOGLE_TRACKING_CODE: googleTrackingCode,
      REACT_APP_GOOGLE_TRACKING_DEBUG: googleTrackingDebug
    } = process.env;
    ReactGA.initialize(googleTrackingCode, {
      debug: googleTrackingDebug
    });
  }
  componentDidUpdate = ({
    location
  }) => {
    ReactGA.pageview(location.pathname);
  }
  render = () => {
    return null;
  }
}

export default withRouter(GoogleAnalytics);