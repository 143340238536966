import React, {
  Component,
  Suspense,
  lazy
} from 'react';
import {
  BrowserRouter,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import {
  withAuthentication
} from '../../components/Firebase';
import LoadingOverlayModal from './LoadingOverlayModal';
import TBIBadWordsFilter, {
  TBIBadWordsFilterContext
} from 'components/TBIBadWordsFilter';
import GoogleAnalytics from 'components/Google/GoogleAnalytics';

const HomePage = lazy(() => import('../../views/HomePage.js'));
// About
const HistoryPage = lazy(() => import('../../views/About/HistoryPage.js'));
const AccommodationPage = lazy(() => import('../../views/About/AccommodationPage.js'));
const BarPage = lazy(() => import('../../views/About/BarPage.js'));
const CafePage = lazy(() => import('../../views/About/CafePage.js'));
const GalleryPage = lazy(() => import('../../views/About/GalleryPage.js'));
const ContactPage = lazy(() => import('../../views/About/ContactPage.js'));
// Community
const InnovationHubPage = lazy(() => import('../../views/Community/InnovationHubPage.js'));
const TechnologyHubPage = lazy(() => import('../../views/Community/TechnologyHubPage.js'));
const LocalBusinessesPage = lazy(() => import('../../views/Community/LocalBusinessesPage.js'));
const BusinessProfilePage = lazy(() => import('../../views/BusinessProfilePage.js'));
const EventsPage = lazy(() => import('../../views/Community/EventsPage.js'));
const EventPage = lazy(() => import('../../views/Community/EventPage.js'));
const MarketplacePage = lazy(() => import('../../views/Community/MarketplacePage.js'));
// Blog
const BlogPostsPage = lazy(() => import('../../views/Blog/BlogPostsPage.js'));
const BlogPostPage = lazy(() => import('../../views/Blog/BlogPostPage.js'));
// ProductServices
const ProductServicesPage = lazy(() => import('../../views/ProductServices/ProductServicesPage.js'));
const ProductServicePage = lazy(() => import('../../views/ProductServices/ProductServicePage.js'));
// Web App  
const RegisterPage = lazy(() => import('../../views/WebApp/RegisterPage.js'));
const LoginPage = lazy(() => import('../../views/WebApp/LoginPage.js'));
const ResetPasswordPage = lazy(() => import('../../views/WebApp/ResetPasswordPage.js'));
const HomeView = lazy(() => import('../../views/WebApp/HomeView.js'));

class App extends Component {
  render = () => {
    return (
      <TBIBadWordsFilterContext.Provider value={new TBIBadWordsFilter({
        firebase: this.props.firebase
      })}>
        <BrowserRouter>
          <Suspense fallback={<LoadingOverlayModal />}>
            <Switch>
              <Route path="/Home" component={HomePage} />
              <Route path="/BusinessProfiles/:bpid" component={BusinessProfilePage} exact={true} />
              <Route path="/BusinessProfiles/:bpid/Events" component={EventsPage} exact={true} />
              <Route path="/BusinessProfiles/:bpid/Events/:eid" component={EventPage} exact={true} />
              <Route path="/BusinessProfiles/:bpid/Gallery" component={GalleryPage} />
              <Route path="/BusinessProfiles/:_bpid/Blog" component={BlogPostsPage} exact={true}/>
              <Route path="/BusinessProfiles/:_bpid/Blog/:bpid" component={BlogPostPage} exact={true}/>
              <Route path="/BusinessProfiles/:bpid/ProductServices" component={ProductServicesPage} exact={true} />
              <Route path="/BusinessProfiles/:bpid/ProductServices/:psid" component={ProductServicePage} exact={true} />
              {/* About */}
              <Route path="/About/History" component={HistoryPage} />
              <Route path="/About/Accommodation" component={AccommodationPage} />
              <Route path="/About/Bar" component={BarPage} />
              <Route path="/About/Cafe" component={CafePage} />
              <Route path="/About/Gallery" component={GalleryPage} />
              <Route path="/About/Contact" component={ContactPage} />
              {/* Community */}
              <Route path="/Community/InnovationHub" component={InnovationHubPage} exact={true} />
              <Route path="/Community/TechnologyHub" component={TechnologyHubPage} exact={true} />
              <Route path="/Community/LocalBusinesses" component={LocalBusinessesPage} exact={true} />
              <Route path="/Community/Events" component={EventsPage} exact={true} />
              <Route path="/Community/Events/:eid" component={EventPage} exact={true} />
              <Route path="/Community/Marketplace" component={MarketplacePage} exact={true} />
              {/* Blog */}
              <Route path="/Blog" component={BlogPostsPage} exact={true}/>
              <Route path="/Blog/:bpid" component={BlogPostPage} exact={true}/>
              {/* Products Services */}
              <Route path="/ProductServices" component={ProductServicesPage} exact={true} />
              <Route path="/ProductServices/:psid" component={ProductServicePage} exact={true} />
              {/* Web App */}
              <Route path="/WebApp/Register" component={RegisterPage} />
              <Route path="/WebApp/Login" component={LoginPage} />
              <Route path="/WebApp/ResetPassword" component={ResetPasswordPage} />
              <Route path="/WebApp/Home" component={HomeView} />
              <Redirect from="/" to="/Home" />
            </Switch>
          </Suspense>
          <GoogleAnalytics />
        </BrowserRouter>
      </TBIBadWordsFilterContext.Provider>
    );
  }
}

export default withAuthentication(App);
