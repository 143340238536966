import React, {
    Component
} from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Button
} from 'reactstrap';
import {
  withFirebase
} from './index';
import FullscreenBackgroundImage from 'components/App/FullscreenBackgroundImage';

const ConfirmationSection = props => (
    <>
    <h3>{props.title}</h3>
    <h5>
      Check your emails (Spam/Junk folder included) for a confirmation email. {props.details}
    </h5>
    </>
);

class EmailVerificationView extends Component {
    constructor(props) {
      super(props);
      this.pageHeaderRef = React.createRef();
      this.state = {
        isSent: false
      };
    }
    onSendEmailVerification = async () => {
      await this.props.firebase
        .sendEmailVerification()
        .then(() => {
          this.setState({
            isSent: true
          });
        });
    };
    render = () => {
        return (
            <>
            <FullscreenBackgroundImage
              hidden
              handleLoad={e => {
                e.preventDefault();
                this.pageHeaderRef.current.style.backgroundImage = `url('${e.target.currentSrc}')`
              }}
              handleError={e => {
                e.preventDefault();
                console.log(`Fullscreen Background Image Error: '${e.target.currentSrc}' was not found.`);
              }}
            />
            <div
              className="page-header"
              style={{
                marginTop: '0',
                paddingTop: "100px"
              }}
              ref={this.pageHeaderRef}
            >
              <div className="filter" />
              <Container>
                <Row>
                  <Col className="mx-auto" md="8">
                    <Card className="no-transition">
                      <CardHeader>
                        <h1>Web App Verification</h1>
                      </CardHeader>
                      <CardBody>
                        {
                          this.state.isSent
                            ? <ConfirmationSection title="Confirmation Sent" details="Refresh this page once you confirmed your email." />
                            : <ConfirmationSection title="Verify your email" details="Or send another confirmation email." />
                        }
                        <Button type="button" block className="btn-round" color="success" outline onClick={this.onSendEmailVerification} disabled={this.state.isSent}>
                          Send confirmation Email
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
            </>
        );
    }
}

export default withFirebase(EmailVerificationView);