import * as firebaseApp from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/analytics';
import {
    /* systemAdminRole,
    adminRole,
    multipleBusinessesRole, */
    businessRole
} from '../../webAppRoles';
import swal from 'sweetalert2';
import {
  localBusinessGroup
} from '../../businessGroups';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

class Firebase {
  constructor(firebaseConfigOverride) {
    this.app = firebaseApp.apps.length
      ? firebaseApp.app()
      : firebaseApp.initializeApp(firebaseConfigOverride || firebaseConfig);
    this.auth = firebaseApp.auth();
    this.db = firebaseApp.database();
    this.storage = firebaseApp.storage();
    this.emailAuthProvider = firebaseApp.auth.EmailAuthProvider;
    this.facebookProvider = new firebaseApp.auth.FacebookAuthProvider();
    this.googleProvider = new firebaseApp.auth.GoogleAuthProvider();
    this.analytics = firebaseApp.analytics();
  }

  /*** Auth APIs ***/
  createUserWithEmailAndPassword = async (email, password, createUserWithEmailAndPassword_Completed) => {
    const authUser = await this.auth.createUserWithEmailAndPassword(email, password),
      {
        uid,
        providerData
      } = authUser.user,
      now = new Date();
    await this.sendEmailVerification();
    await this.saveDbUser({
      active: true,
      created: now.toString(),
      createdBy: uid,
      displayName: '',
      email: email,
      photoURL: '',
      providerData: providerData,
      roles: {
          businessRole
      },
      uid: uid,
      updated: now.toString(),
      updatedBy: uid
    }, createUserWithEmailAndPassword_Completed);
    return authUser;
  }

  signInWithEmailAndPassword = async (email, password) => {
    return await this.auth.signInWithEmailAndPassword(email, password);
  }

  signInWithFacebook = async signInWithFacebook_Completed => {
    const authUser = await this.auth.signInWithPopup(this.facebookProvider),
      {
        uid,
        displayName,
        email,
        photoURL,
        providerData
      } = authUser.user,
      dbUser = await this.getDbUserValue(uid),
      now = new Date();
    if (!dbUser) {
      await this.saveDbUser({
          active: true,
          created: now.toString(),
          createdBy: uid,
          displayName: displayName,
          email: email,
          photoURL: photoURL,
          providerData: providerData,
          roles: {
              businessRole
          },
          uid: uid,
          updated: now.toString(),
          updatedBy: uid
        }, signInWithFacebook_Completed);
    } else if (typeof signInWithFacebook_Completed === 'function') {
      signInWithFacebook_Completed(null);
    }
    return authUser;
  }

  signInWithGoogle = async signInWithGoogle_Completed => {
    const authUser = await this.auth.signInWithPopup(this.googleProvider),
      {
        uid,
        displayName,
        email,
        photoURL,
        providerData
      } = authUser.user,
      dbUser = await this.getDbUserValue(uid),
      now = new Date();
    if (!dbUser) {
      await this.saveDbUser({
        active: true,
        created: now.toString(),
        createdBy: uid,
        displayName: displayName,
        email: email,
        photoURL: photoURL,
        providerData: providerData,
        roles: {
            businessRole
        },
        uid: uid,
        updated: now.toString(),
        updatedBy: uid
      }, signInWithGoogle_Completed);
    } else if (typeof signInWithGoogle_Completed === 'function') {
      signInWithGoogle_Completed(null);
    }
    return authUser;
  }

  signOut = async () => {
    return await this.auth.signOut();
  }

  sendPasswordResetEmail = async email => {
    return await this.auth.sendPasswordResetEmail(email);
  }

  sendEmailVerification = async () => {
    return await this.auth.currentUser.sendEmailVerification();
    /* return await this.auth.currentUser.sendEmailVerification({
        url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    }); */
  }

  reauthenticate = async currentPassword => {
    const user = this.auth.currentUser,
      userCredentials = await this.emailAuthProvider.credential(user.email, currentPassword);
    return await user.reauthenticateWithCredential(userCredentials);
  }

  getUserCredentials = async providerId => {
    let result = null,
      errorMessage = null;
    try {
      if (providerId === 'password') {
        result = await swal.fire({
          title: 'Current Password',
          input: 'password',
          inputPlaceholder: 'Enter your current password',
          showCancelButton: true,
          footer: 'Need to verify your credentials before continuing...'
        });
        if (!!result.value) {
          return await this.reauthenticate(result.value);
        }
      } else {
        const provider = providerId === 'facebook.com'
          ? this.facebookProvider
          : providerId === 'google.com'
            ? this.googleProvider
            : null
        return await this.auth.signInWithPopup(provider);
      }
    } catch (error) {
      errorMessage = error.message;
    }
    if (errorMessage) {
      console.log('Get User Credentials Error: ' + errorMessage);
      return errorMessage;
    }
  }

  changeEmail = async e => {
    e.preventDefault();
    const user = this.auth.currentUser, {
        uid
    } = user;
    let result = null,
      newEmail = null,
      errorMessage = null;
    try {
      const userCredentials = await this.getUserCredentials();
      if (userCredentials && typeof userCredentials !== 'string' && userCredentials.user) {
        result = await swal.fire({
          title: 'Change Email',
          input: 'email',
          inputPlaceholder: 'Enter your new email',
          showCancelButton: true
        });
        newEmail = result.value;
        if (newEmail) {
          try {
            await user.updateEmail(newEmail);
            if (!user.emailVerified) {
              await user.sendEmailVerification();
              await this.saveDbUser({
                uid: uid,
                email: newEmail,
                updatedBy: uid
              });
              await this.signOut();
              await swal.fire({
                type: 'success',
                title: 'Change Email Successful',
                text: 'Please verify your email to login.'
              });
              return;
            }
          } catch (error) {
            errorMessage = 'Unable to update your email to <b>' + newEmail + '</b>. Reason: ' + error.message;
          }
        }
      } else {
        errorMessage = userCredentials;
      }
    } catch (error) {
      errorMessage = error.message;
    }
    if (errorMessage) {
      swal.fire({
        type: 'error',
        title: 'Change Email Error',
        html: errorMessage
      });
      console.log('Change Email Error: ' + errorMessage);
    }
  }

  changePassword = async e => {
    e.preventDefault();
    let result = null,
      newPassword = null,
      confirmPassword = null,
      errorMessage = null;
    try {
      const userCredentials = await this.getUserCredentials();
      if (userCredentials && typeof userCredentials !== 'string' && userCredentials.user) {
        result = await swal.fire({
          title: 'New Password',
          input: 'password',
          inputPlaceholder: 'Enter your new password',
          showCancelButton: true
        });
        newPassword = result.value;
        result = await swal.fire({
          title: 'Confirm Password',
          input: 'password',
          inputPlaceholder: 'Re-enter your new password',
          showCancelButton: true
        });
        confirmPassword = result.value;
        if (newPassword && confirmPassword && newPassword === confirmPassword) {
          try {
            await this.updatePassword(newPassword);
            await this.signOut();
            await swal.fire({
              type: 'success',
              title: 'Change Password Successful',
              text: 'Please login again to confirm your new password.'
            });
            return;
          } catch (error) {
            errorMessage = 'Unable to update your password to <b>' + newPassword + '</b>. Reason: ' + error.message;
          }
        } else {
          errorMessage = 'New Password does not match Confirm Password.';
        }
      } else {
        errorMessage = userCredentials;
      }
    } catch (error) {
      errorMessage = error.message;
    }
    if (errorMessage) {
      swal.fire({
        type: 'error',
        title: 'Change Password Error',
        html: errorMessage
      });
      console.log('Change Password Error: ' + errorMessage);
    }
  }

  updatePassword = async password => {
    return await this.auth.currentUser.updatePassword(password);
  }
  
  authUserListener = async (next, fallback) => {
    return await this.auth.onAuthStateChanged(async authUser => {
      if (authUser) {
        const dbUser = await this.getDbUserValue(authUser.uid);
        if (dbUser && !dbUser.roles) {
          dbUser.roles = {
            businessRole
          };
        }
        authUser = {
          uid: authUser.uid,
          email: authUser.email,
          emailVerified: authUser.emailVerified,
          providerData: authUser.providerData,
          ...dbUser,
        };
        next(authUser);
      } else {
        fallback();
      }
    })
  }

  deleteAccount = async e => {
    e.preventDefault();
    let result = null,
      errorMessage = null;
    try {
      result = await swal.fire({
        type: 'warning',
        title: 'Are you sure?',
        text: "You won't be able to undo this!",
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-outline-link',
        }
      });
      if (!!result.value) {
        const user = this.auth.currentUser,
          providerId = user.providerData[0].providerId,
          userCredentials = await this.getUserCredentials(providerId);
        if (userCredentials && typeof userCredentials !== 'string' && userCredentials.user) {
          if (providerId === 'password' && userCredentials.user.photoURL) {
            await this.deleteStorageFile(userCredentials.user.photoURL);
          }
          await this.deleteDbUser(userCredentials.user.uid);
          await userCredentials.user.delete();
          //await this.signOut();
          swal.fire({
            type: 'success',
            title: 'Delete Account Successful',
            text: 'Your account has been deleted.'
          });
        } else {
          errorMessage = userCredentials;
        }
      }
    } catch (error) {
      errorMessage = error.message;
    }
    if (errorMessage) {
      swal.fire({
        type: 'error',
        title: 'Delete Account Error',
        html: errorMessage
      });
      console.log('Delete Account Error: ' + errorMessage);
    }
  }

  /*** DB APIs ***/
  // Users
  getDbUsers = async () => {
    return await this.db.ref('users');
  }

  getDbUser = async uid => {
    return await this.db.ref(`users/${uid}`);
  }

  getDbUserValue = async uid => {
    const existingDbUser = await this.getDbUser(uid),
      snapshot = await existingDbUser.once('value'),
      dbUser = await snapshot.val();
    return dbUser;
  }

  saveDbUser = async (user, saveDbUser_completed) => {
    const {
        active,
        businessProfiles,
        created,
        createdBy,
        displayName,
        email,
        photoURL,
        providerData,
        roles,
        uid,
        updated,
        updatedBy
      } = user,
      existingDbUser = await this.getDbUser(uid),
      now = new Date();
    let errorMessage = null,
      dbUserRef = null,
      dbUser = null;
    if (existingDbUser) {
      dbUserRef = await existingDbUser.once('value');
      dbUser = dbUserRef.val();
      if (dbUser) {
        user = {
          active: (typeof active === 'boolean' && active) || (typeof active !== 'boolean' && !!dbUser.active),
          businessProfiles: businessProfiles || dbUser.businessProfiles || null,
          created: created || dbUser.created,
          createdBy: createdBy || dbUser.createdBy,
          displayName: displayName || dbUser.displayName || '',
          email: email || dbUser.email,
          photoURL: photoURL || dbUser.photoURL || '',
          providerData: providerData || dbUser.providerData || {},
          roles: roles || dbUser.roles || {
              businessRole
          },
          uid: uid,
          updated: updated || now.toString(),
          updatedBy: updatedBy || uid
        }
      }
      existingDbUser.set(user, saveDbUser_completed);
    } else {
      errorMessage = 'Save Db User Error: uid (' + uid + ') not found.';
    }
    if (errorMessage) {
      console.log('Save Db User Error: ' + errorMessage);
      throw new Error(errorMessage);
    }
  }

  deleteDbUser = async uid => {
    const existingDbUser = await this.getDbUser(uid);
    let errorMessage = null;
    if (existingDbUser) {
      await existingDbUser.remove();
    } else {
      errorMessage = 'Delete Db User Error: uid (' + uid + ') not found.';
    }
    if (errorMessage) {
      console.log('Delete Db User Error: ' + errorMessage);
      throw new Error(errorMessage);
    }
  }

  // Blog Posts
  getDbBlogPosts = async () => {
    return await this.db.ref('blogPosts');
  }

  getDbBlogPost = async bpid => {
    return await this.db.ref(`blogPosts/${bpid}`);
  }

  saveDbBlogPost = async (blogPost, saveDbBlogPost_completed) => {
    const {
        active,
        created,
        createdBy,
        publishDate,
        _bpid,
        bpid,
        title,
        content,
        authorUid,
        backgroundImage,
        updated,
        updatedBy,
        uid
      } = blogPost,
      now = new Date();
    let errorMessage = null,
      existingDbBlogPost = await this.getDbBlogPost(bpid),
      dbBlogPostRef = null,
      dbBlogPost = null;
    if (!bpid) {
      dbBlogPostRef = await existingDbBlogPost.push();
      blogPost = {
        active: active,
        created: created,
        createdBy: createdBy,
        publishDate: publishDate || '',
        _bpid: _bpid || null,
        bpid: await dbBlogPostRef.getKey(),
        title: title || '',
        content: content || '',
        backgroundImage: backgroundImage || '',
        authorUid: authorUid || '',
        updated: updated || now.toString(),
        updatedBy: updatedBy || uid
      };
      dbBlogPostRef.set(blogPost, saveDbBlogPost_completed);
    } else {
      dbBlogPostRef = await existingDbBlogPost.once('value');
      dbBlogPost = await dbBlogPostRef.val();
      if (dbBlogPost) {
        blogPost = {
          active: (typeof active === 'boolean' && active) || (typeof active !== 'boolean' && !!dbBlogPost.active),
          created: created || dbBlogPost.created,
          createdBy: createdBy || dbBlogPost.createdBy,
          publishDate: publishDate || dbBlogPost.publishDate || '',
          _bpid: _bpid || dbBlogPost._bpid || null,
          bpid: bpid,
          title: title || dbBlogPost.title || '',
          content: content || dbBlogPost.content || '',
          backgroundImage: backgroundImage || dbBlogPost.backgroundImage || '',
          authorUid: authorUid || dbBlogPost.authorUid || '',
          updated: updated || now.toString(),
          updatedBy: updatedBy || uid
        };
        existingDbBlogPost.set(blogPost, saveDbBlogPost_completed);
      } else {
        errorMessage = 'Save Db Blog Post Error: bpid (' + bpid + ') not found.';
      }
    }
    if (errorMessage) {
      console.log('Save Db Blog Post Error: ' + errorMessage);
      throw new Error(errorMessage);
    }
  }

  deleteDbBlogPost = async bpid => {
    const existingDbBlogPost = await this.getDbBlogPost(bpid);
    let errorMessage = null;
    if (existingDbBlogPost) {
      await existingDbBlogPost.remove();
    } else {
      errorMessage = 'Delete Db Blog Post Error: bpid (' + bpid + ') not found.';
    }
    if (errorMessage) {
      console.log('Delete Db Blog Post Error: ' + errorMessage);
      throw new Error(errorMessage);
    }
  }
  
  // Gallery Images
  getDbGalleryImages = async () => {
    return await this.db.ref('galleryImages');
  }

  getDbGalleryImage = async giid => {
    return await this.db.ref(`galleryImages/${giid}`);
  }

  saveDbGalleryImage = async (galleryImage, saveDbGalleryImage_completed) => {
    let {
        active,
        created,
        createdBy,
        alt,
        bpid,
        giid,
        imageURL,
        monthYear,
        title,
        description,
        updated,
        updatedBy,
        uid
      } = galleryImage,
      now = new Date();
    let errorMessage = null,
      existingDbGalleryImage = await this.getDbGalleryImage(giid),
      dbGalleryImageRef = null,
      dbGalleryImage = null;
    if (!giid) {
      dbGalleryImageRef = await existingDbGalleryImage.push();
      giid = await dbGalleryImageRef.getKey();
      galleryImage = {
        active: active,
        created: created,
        createdBy: createdBy,
        bpid: bpid || null,
        alt: alt || '',
        giid: giid,
        imageURL: imageURL
          ? imageURL.replace('New', giid)
          : '',
        monthYear: monthYear || '',
        title: title || '',
        description: description || '',
        updated: updated || now.toString(),
        updatedBy: updatedBy || uid
      };
      dbGalleryImageRef.set(galleryImage, saveDbGalleryImage_completed);
    } else {
      dbGalleryImageRef = await existingDbGalleryImage.once('value');
      dbGalleryImage = await dbGalleryImageRef.val();
      if (dbGalleryImage) {
        galleryImage = {
          active: (typeof active === 'boolean' && active) || (typeof active !== 'boolean' && !!dbGalleryImage.active),
          created: created || dbGalleryImage.created,
          createdBy: createdBy || dbGalleryImage.createdBy,
          alt: alt || dbGalleryImage.alt || '',
          bpid: bpid || dbGalleryImage.bpid || null,
          giid: giid,
          imageURL: imageURL || dbGalleryImage.imageURL || '',
          monthYear: monthYear || dbGalleryImage.monthYear || '',
          title: title || dbGalleryImage.title || '',
          description: description || dbGalleryImage.description || '',
          updated: updated || now.toString(),
          updatedBy: updatedBy || uid
        };
        existingDbGalleryImage.set(galleryImage, saveDbGalleryImage_completed);
      } else {
        errorMessage = 'Save Db Gallery Image Error: giid (' + giid + ') not found.';
      }
    }
    if (errorMessage) {
      console.log('Save Db Gallery Image Error: ' + errorMessage);
      throw new Error(errorMessage);
    }
    return galleryImage.giid;
  }

  deleteDbGalleryImage = async giid => {
    const existingDbGalleryImage = await this.getDbGalleryImage(giid);
    let errorMessage = null;
    if (existingDbGalleryImage) {
      await existingDbGalleryImage.remove();
    } else {
      errorMessage = 'Delete Db Gallery Image Error: giid (' + giid + ') not found.';
    }
    if (errorMessage) {
      console.log('Delete Db Gallery Image Error: ' + errorMessage);
      throw new Error(errorMessage);
    }
  }

  // Events
  getDbEvents = async () => {
    return await this.db.ref('events');
  }

  getDbEvent = async eid => {
    return await this.db.ref(`events/${eid}`);
  }

  saveDbEvent = async (event, saveDbEvent_completed) => {
    const {
        active,
        bpid,
        created,
        createdBy,
        date,
        eid,
        endTime,
        monthYear,
        name,
        startTime,
        synopsis,
        updated,
        updatedBy,
        uid
      } = event,
      now = new Date();
    let errorMessage = null,
      existingDbEvent = await this.getDbEvent(eid),
      dbEventRef = null,
      dbEvent = null;
    if (!eid) {
      dbEventRef = await existingDbEvent.push();
      event = {
        active: active,
        bpid: bpid || null,
        created: created,
        createdBy: createdBy,
        date: date || '',
        eid: await dbEventRef.getKey(),
        endTime: endTime || '',
        monthYear: monthYear || '',
        name: name || '',
        startTime: startTime || '',
        synopsis: synopsis || '',
        updated: updated || now.toString(),
        updatedBy: updatedBy || uid
      };
      dbEventRef.set(event, saveDbEvent_completed);
    } else {
      dbEventRef = await existingDbEvent.once('value');
      dbEvent = await dbEventRef.val();
      if (dbEvent) {
        event = {
          active: (typeof active === 'boolean' && active) || (typeof active !== 'boolean' && !!dbEvent.active),
          bpid: bpid || dbEvent.bpid || null,
          created: created || dbEvent.created,
          createdBy: createdBy || dbEvent.createdBy,
          date: date || dbEvent.date || '',
          eid: eid,
          endTime: endTime || dbEvent.endTime || '',
          monthYear: monthYear || dbEvent.monthYear || '',
          name: name || dbEvent.name || '',
          startTime: startTime || dbEvent.startTime || '',
          synopsis: synopsis || dbEvent.synopsis || '',
          updated: updated || now.toString(),
          updatedBy: updatedBy || uid
        };
        existingDbEvent.set(event, saveDbEvent_completed);
      } else {
        errorMessage = 'Save Db Event Error: eid (' + eid + ') not found.';
      }
    }
    if (errorMessage) {
      console.log('Save Db Event Error: ' + errorMessage);
      throw new Error(errorMessage);
    }
  }

  deleteDbEvent = async eid => {
    const existingDbEvent = await this.getDbEvent(eid);
    let errorMessage = null;
    if (existingDbEvent) {
      await existingDbEvent.remove();
    } else {
      errorMessage = 'Delete Db Event Error: eid (' + eid + ') not found.';
    }
    if (errorMessage) {
      console.log('Delete Db Event Error: ' + errorMessage);
      throw new Error(errorMessage);
    }
  }
  // Products Services
  getDbProductServices = async () => {
    return await this.db.ref('productServices');
  }

  getDbProductService = async psid => {
    return await this.db.ref(`productServices/${psid}`);
  }

  saveDbProductService = async (productService, saveDbProductService_completed) => {
    const {
        active,
        created,
        createdBy,
        date,
        psid,
        bpid,
        name,
        description,
        price,
        imageURL,
        updated,
        updatedBy,
        uid
      } = productService,
      now = new Date();
    let errorMessage = null,
      existingDbProductService = await this.getDbProductService(psid),
      dbProductServiceRef = null,
      dbProductService = null;
    if (!psid) {
      dbProductServiceRef = await existingDbProductService.push();
      productService = {
        active: active,
        created: created,
        createdBy: createdBy,
        date: date || '',
        psid: await dbProductServiceRef.getKey(),
        bpid: bpid || null,
        name: name || '',
        description: description || '',
        price: price || 0.00,
        imageURL: imageURL || '',
        updated: updated || now.toString(),
        updatedBy: updatedBy || uid
      };
      dbProductServiceRef.set(productService, saveDbProductService_completed);
    } else {
      dbProductServiceRef = await existingDbProductService.once('value');
      dbProductService = await dbProductServiceRef.val();
      if (dbProductService) {
        productService = {
          active: (typeof active === 'boolean' && active) || (typeof active !== 'boolean' && !!dbProductService.active),
          created: created || dbProductService.created,
          createdBy: createdBy || dbProductService.createdBy,
          date: date || dbProductService.date || '',
          psid: psid,
          bpid: bpid || dbProductService.bpid || null,
          name: name || dbProductService.name || '',
          description: description || dbProductService.description || '',
          price: price || dbProductService.price || 0.00,
          imageURL: imageURL || dbProductService.imageURL || '',
          updated: updated || now.toString(),
          updatedBy: updatedBy || uid
        };
        existingDbProductService.set(productService, saveDbProductService_completed);
      } else {
        errorMessage = 'Save Db Product Service Error: psid (' + psid + ') not found.';
      }
    }
    if (errorMessage) {
      console.log('Save Db Product Service Error: ' + errorMessage);
      throw new Error(errorMessage);
    }
  }

  deleteDbProductService = async psid => {
    const existingDbProductService = await this.getDbProductService(psid);
    let errorMessage = null;
    if (existingDbProductService) {
      await existingDbProductService.remove();
    } else {
      errorMessage = 'Delete Db Product Service Error: psid (' + psid + ') not found.';
    }
    if (errorMessage) {
      console.log('Delete Db Product Service Error: ' + errorMessage);
      throw new Error(errorMessage);
    }
  }
  // Business Profiles
  getDbBusinessProfiles = async () => {
    return await this.db.ref('businessProfiles');
  }

  getDbBusinessProfile = async bpid => {
    return await this.db.ref(`businessProfiles/${bpid}`);
  }

  getDbBusinessProfileValue = async uid => {
    const existingDbBusinessProfile = await this.getDbBusinessProfile(uid),
      snapshot = await existingDbBusinessProfile.once('value'),
      dbBusinessProfile = await snapshot.val();
    return dbBusinessProfile;
  }

  saveDbBusinessProfile = async (businessProfile, saveDbBusinessProfile_completed) => {
    const {
        active,
        created,
        createdBy,
        bpid,
        businessGroups,
        name,
        description,
        backgroundImage,
        logo,
        physicalAddress,
        postalAddress,
        contactPersonUid,
        coreLinks,
        socialMediaLinks,
        updated,
        updatedBy,
        uid
      } = businessProfile,
      now = new Date();
    let errorMessage = null,
      existingDbBusinessProfile = await this.getDbBusinessProfile(bpid),
      dbBusinessProfileRef = null,
      dbBusinessProfile = null;
    if (!bpid) {
      dbBusinessProfileRef = await existingDbBusinessProfile.push();
      businessProfile = {
        active: active,
        created: created,
        createdBy: createdBy,
        bpid: await dbBusinessProfileRef.getKey(),
        businessGroups: businessGroups || {
          localBusinessGroup
        },
        name: name || '',
        description: description || '',
        backgroundImage: backgroundImage || '',
        logo: logo || '',
        physicalAddress: physicalAddress || '',
        postalAddress: postalAddress || '',
        contactPersonUid: contactPersonUid || '',
        coreLinks: coreLinks,
        socialMediaLinks: socialMediaLinks,
        updated: updated || now.toString(),
        updatedBy: updatedBy || uid
      };
      dbBusinessProfileRef.set(businessProfile, saveDbBusinessProfile_completed);
    } else {
      dbBusinessProfileRef = await existingDbBusinessProfile.once('value');
      dbBusinessProfile = await dbBusinessProfileRef.val();
      if (dbBusinessProfile) {
        businessProfile = {
          active: (typeof active === 'boolean' && active) || (typeof active !== 'boolean' && !!dbBusinessProfile.active),
          created: created || dbBusinessProfile.created,
          createdBy: createdBy || dbBusinessProfile.createdBy,
          bpid: bpid,
          businessGroups: businessGroups || dbBusinessProfile.businessGroups || {
            localBusinessGroup
          },
          name: name || dbBusinessProfile.name || '',
          description: description || dbBusinessProfile.description || '',
          backgroundImage: backgroundImage || dbBusinessProfile.backgroundImage || '',
          logo: logo || dbBusinessProfile.logo || '',
          physicalAddress: physicalAddress || dbBusinessProfile.physicalAddress || '',
          postalAddress: postalAddress || dbBusinessProfile.postalAddress || '',
          contactPersonUid: contactPersonUid || dbBusinessProfile.contactPersonUid || '',
          coreLinks: coreLinks || dbBusinessProfile.coreLinks || null,
          socialMediaLinks: socialMediaLinks || dbBusinessProfile.socialMediaLinks || null,
          updated: updated || now.toString(),
          updatedBy: updatedBy || uid
        };
        existingDbBusinessProfile.set(businessProfile, saveDbBusinessProfile_completed);
      } else {
        errorMessage = 'Save Db Business Profile Error: bpid (' + bpid + ') not found.';
      }
    }
    if (errorMessage) {
      console.log('Save Db Business Profile Error: ' + errorMessage);
      throw new Error(errorMessage);
    }
    return businessProfile.bpid;
  }  

  deleteDbBusinessProfile = async bpid => {
    const existingDbBusinessProfile = await this.getDbBusinessProfile(bpid);
    let errorMessage = null;
    if (existingDbBusinessProfile) {
      await existingDbBusinessProfile.remove();
    } else {
      errorMessage = 'Delete Db Business Profile Error: bpid (' + bpid + ') not found.';
    }
    if (errorMessage) {
      console.log('Delete Db Business Profile Error: ' + errorMessage);
      throw new Error(errorMessage);
    }
  }

  // Settings
  getDbSettings = async sid => {
    return sid
      ? await this.db.ref(`settings/${sid}`)
      : await this.db.ref('settings');
  }

  saveDbSettings = async (settings, saveDbSettings_completed) => {
    const {
        active,
        created,
        createdBy,
        sid,
        coreLinks,
        name,
        badWordsList,
        badWordsExcludedList,
        updated,
        updatedBy,
        uid
      } = settings,
      now = new Date();
    let errorMessage = null,
      existingDbSettings = await this.getDbSettings(sid),
      dbSettingsRef = null,
      dbSettings = null;
    if (!sid) {
      dbSettingsRef = await existingDbSettings.push();
      settings = {
        active: active,
        created: created,
        createdBy: createdBy,
        sid: await dbSettingsRef.getKey(),
        name: name || '',
        coreLinks: coreLinks || {},
        badWordsList: badWordsList || [],
        badWordsExcludedList: badWordsExcludedList || [],
        updated: updated || now.toString(),
        updatedBy: updatedBy || uid
      };
      dbSettingsRef.set(settings, saveDbSettings_completed);
    } else {
      dbSettingsRef = await existingDbSettings.once('value');
      dbSettings = await dbSettingsRef.val();
      if (dbSettings) {
        settings = {
          active: (typeof active === 'boolean' && active) || (typeof active !== 'boolean' && !!dbSettings.active),
          created: created || dbSettings.created,
          createdBy: createdBy || dbSettings.createdBy,
          sid: sid,
          name: name || dbSettings.name || '',
          coreLinks: coreLinks || dbSettings.coreLinks || {},
          badWordsList: badWordsList || dbSettings.badWordsList || [],
          badWordsExcludedList: badWordsExcludedList || [],
          updated: updated || now.toString(),
          updatedBy: updatedBy || uid
        };
        existingDbSettings.set(settings, saveDbSettings_completed);
      } else {
        errorMessage = 'Save Db Settings Error: sid (' + sid + ') not found.';
      }
    }
    if (errorMessage) {
      console.log('Save Db Settings Error: ' + errorMessage);
      throw new Error(errorMessage);
    }
  }

  deleteDbSettings = async sid => {
    const existingDbSettings = await this.getDbSettings(sid);
    let errorMessage = null;
    if (existingDbSettings) {
      await existingDbSettings.remove();
    } else {
      errorMessage = 'Delete Db Settings Error: sid (' + sid + ') not found.';
    }
    if (errorMessage) {
      console.log('Delete Db Settings Error: ' + errorMessage);
    }
  }
  
  // Contact Messages
  getDbContactMessages = async () => {
    return await this.db.ref('contactMessages');
  }

  getDbContactMessage = async cmid => {
    return await this.db.ref(`contactMessages/${cmid}`);
  }

  saveDbContactMessage = async (contactMessage, saveDbContactMessage_completed) => {
    const {
        active,
        created,
        createdBy,
        cmid,
        bpid,
        name,
        email,
        message,
        updated,
        updatedBy,
        uid
      } = contactMessage,
      now = new Date();
    let errorMessage = null,
      existingDbContactMessage = await this.getDbContactMessage(cmid),
      dbContactMessageRef = null,
      dbContactMessage = null;
    if (!cmid) {
      dbContactMessageRef = await existingDbContactMessage.push();
      contactMessage = {
        active: active,
        created: created,
        createdBy: createdBy,
        cmid: await dbContactMessageRef.getKey(),
        bpid: bpid || null,
        name: name || '',
        email: email || '',
        message: message || '',
        updated: updated || now.toString(),
        updatedBy: updatedBy || uid
      };
      dbContactMessageRef.set(contactMessage, saveDbContactMessage_completed);
    } else {
      dbContactMessageRef = await existingDbContactMessage.once('value');
      dbContactMessage = await dbContactMessageRef.val();
      if (dbContactMessage) {
        contactMessage = {
          active: (typeof active === 'boolean' && active) || (typeof active !== 'boolean' && !!dbContactMessage.active),
          created: created || dbContactMessage.created,
          createdBy: createdBy || dbContactMessage.createdBy,
          cmid: cmid,
          bpid: bpid || dbContactMessage.bpid || null,
          name: name || dbContactMessage.name || '',
          email: email || dbContactMessage.email || '',
          message: message || dbContactMessage.message || '',
          updated: updated || now.toString(),
          updatedBy: updatedBy || uid
        };
        existingDbContactMessage.set(contactMessage, saveDbContactMessage_completed);
      } else {
        errorMessage = 'Save Db Contact Message Error: cmid (' + cmid + ') not found.';
      }
    }
    if (errorMessage) {
      console.log('Save Db Contact Message Error: ' + errorMessage);
      throw new Error(errorMessage);
    }
  }

  deleteDbContactMessage = async cmid => {
    const existingDbContactMessage = await this.getDbContactMessage(cmid);
    let errorMessage = null;
    if (existingDbContactMessage) {
      await existingDbContactMessage.remove();
    } else {
      errorMessage = 'Delete Db Contact Message Error: cmid (' + cmid + ') not found.';
    }
    if (errorMessage) {
      console.log('Delete Db Contact Message Error: ' + errorMessage);
      throw new Error(errorMessage);
    }
  }

  /*** Storage APIs ***/
  getStorageFileRef = path => {
    return this.storage.ref().child(path);
  }

  getStorageFileDownloadURL = async path => {
    return await this.getStorageFileRef(path).getDownloadURL();
  }

  saveStorageFile = async (path, file) => {
    return await this.getStorageFileRef(path).put(file);
  }

  deleteStorageFile = async (path) => {
    return await this.getStorageFileRef(path).delete();
  }
}

export default Firebase;